<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">考试管理</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <a class="crumbs_item" @click="$router.go(-1)">考试报告 / </a>
          <span class="crumbs_item crumbs_last">分数段设置</span>
        </div>
      </div>
    </div>
    <div class="content_box">
      <div class="ul-list">
        <div class="li-item" v-for="(item, key) in listData" :key="item.courseId">
          <div class="exam-title">
            <p class="h1">{{item.examName}}</p><span @click="onAdd(key)">新建分数段</span>
          </div>
          <div class="score-inner-item" v-for="(child, value) in item.scoreList" :key="'key:' + item.courseId + '-' + value">
            <div class="score-l">
              <a-input-number class="number" :disabled="Boolean(child.isDisable)" placeholder="最小值" v-model="child.minScore" id="inputNumber" :min="0" :max="300" /> 分
              <span>~</span>
              <a-input-number class="number" :disabled="Boolean(child.isDisable)" placeholder="最大值" v-model="child.maxScore" id="inputNumber" :min="0" :max="300" /> 分
            </div>
            <div class="option">
              <a v-if="Boolean(child.isDisable)" @click="onEdit(child)">编辑 | </a>
              <a v-else @click="onSave(item.courseId,child)">保存 | </a>
              <a>
                <a-popconfirm
                  title="是否确认删除该数据?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="onDel(key, value, child.reportConfigId)"
                >
                  <a href="#">删除</a>
                </a-popconfirm>
              </a>
            </div>
          </div>
        </div>
      </div>
      <a-spin class="spin" v-show="loading" tip="加载中..." />
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loading: false,
      listData:[]
    }
  },
  // 事件处理器
  methods: {
    onEdit(e){
      e.isDisable = false
    },
    // 保存或编辑
    onSave(courseId,e){
      if(!e.minScore){
        this.$message.warning('请输入分数段最小值!');
        return
      }
      if(!e.maxScore){
        this.$message.warning('请输入分数段最大值!');
        return
      }
      if(Number(e.maxScore) < Number(e.minScore)){
        this.$message.warning('分数段最小值不得大于最大值!');
        return
      }
      this.loading = true
      let method = e.reportConfigId ? 'put' : 'POST'
      let url = e.reportConfigId ? "/hxclass-management/exam/report/config/update" : "/hxclass-management/exam/report/config/save"
      this.$ajax({
        method: method,
        url: url,
        params: {
          courseId: courseId, 
          maxScore: e.maxScore,
          minScore: e.minScore,
          reportConfigId: e.reportConfigId ? e.reportConfigId : null,
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          e.isDisable = true
          e.reportConfigId = e.reportConfigId ? e.reportConfigId : res.data
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 增加分数段
    onAdd(key){
      let arry = this.listData[key].scoreList
      arry.unshift({
        minScore:'',
        maxScore:'',
        reportConfigId: 0,
        isDisable: false
      })
    },
    // 删除分数段
    onDel(key, value, id){
      if(id){
        this.loading = true
        this.$ajax({
          method: 'put',
          url:"/hxclass-management/exam/report/config/deleted?reportConfigId=" + id,
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            let arry = this.listData[key].scoreList
            arry.splice(value,1)
            this.$message.success('删除成功！');
          } else {
            this.$message.warning(res.message);
          }
        })
      } else {
        let arry = this.listData[key].scoreList
        arry.splice(value,1)
      }
      
    },
    // 获取分数段列表
    getListData(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/report/config/list",
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.listData = res.data
        } else {
          this.listData = []
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getListData()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.content_box{
  margin-top: 20px;
  min-height: 300px;
  position: relative;
  .ul-list{
    .li-item{
      min-height: 40px;
      margin-bottom: 40px;
      .exam-title{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .h1{
          font-weight: 600;
        }
        span{
          margin-left: 16px;
          font-size: 14px;
          color: #3681f0;
          cursor: pointer;
        }
      }
      .score-inner-item{
        max-width: 450px;
        display: flex;
        align-items: center;
        border: 1px solid #666666;
        border-bottom: none;
        .score-l{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
          border-right: 1px solid #666666;
          min-width: 310px;
          .number{
            margin-right: 10px;
          }
          >span{
            margin: 0 10px;
          }
        }
        .option{
          flex: 1;
          text-align: center;
          a{
            color: #3681f0;
          }
        }
      }
      .score-inner-item:last-child{
        border-bottom: 1px solid #666666;
      }
    }
  }
  .spin{
    position:absolute;
    width:60px;
    height:60px;
    left:50%;
    top:50%;
    margin-left:-50px;   //这两行代码也可以替换成
    margin-top:-50px;    //transform:translate(-50%,-50%);
  }
}
</style>
